import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Quote from "./Quote";
import SocialMedia from "./SocialMedia";

export default function AboutPage({ ReactGA }) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/about-me" });
  }, [ReactGA]);

  return (
    <>
      <section className="px-2 py-32 bg-white md:px-0">
        <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
          <div className="flex flex-wrap items-center sm:-mx-3">
            <div className="w-full md:w-1/2 md:px-3">
              <Link to="/">
                <span className="block xl:inline font-['Courier_Prime'] hover:cursor-pointer">
                  ⬅ back
                </span>
              </Link>

              <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl">
                  <br />
                  <span className="block text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-red-400 to-purple-600 font-['Courier_Prime'] xl:inline">
                    HELLO WORLD!
                  </span>
                </h1>
                <p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-xl md:max-w-3xl">
                  Hi I am <b>Shiva Kumar Mangina</b>, a 28 year old ML
                  and Web developer, YouTuber living in Bangalore, India. I am a
                  programmer by profession. I
                  love to <code className="bg-slate-200">code</code> in{" "}
                  <code className="bg-slate-200">JavaScript</code>. I am always
                  excited to do business with like minded people,{" "}
                  <b>Let’s work together on your great idea</b>. Connect with me
                  on{" "}
                  <code className="italic bg-yellow-100">
                    shiva2nani.mangina@gmail.com
                  </code>{" "}
                  or{" "}
                  <a
                    className="bg-sky-200"
                    href="https://www.linkedin.com/in/shivamangina/"
                    onClickCapture={() =>
                      ReactGA.event({
                        category: "Social Media",
                        action: "Link Click",
                        label: "LinkedIn",
                      })
                    }
                    
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    LinkedIn
                  </a>
                  .
                </p>

                <SocialMedia ReactGA={ReactGA} />
              </div>
            </div>
            <div className="w-full mx-auto md:w-1/4 sm:w-1/2">
              <div className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl">
                <img
                  src="https://avatars.githubusercontent.com/u/50368708?v=4"
                  alt="shivakumarmangina"
                />
              </div>
              <a
                href="https://www.linkedin.com/in/shivakumarmangina/"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Eth</span>
                <code className="">shivamangina.eth</code>
              </a>
            </div>
          </div>
        </div>
      </section>
      <Quote />

      <section className="h-auto bg-white">
        <div className="max-w-7xl mx-auto py-16 px-10 sm:py-24 sm:px-6 lg:px-8 sm:text-center">
          <h2 className="text-base font-semibold text-purple-600 tracking-wide uppercase">
            Still there?
          </h2>
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Hello? 👋
          </p>
          <p className="max-w-3xl mt-5 mx-auto text-xl text-gray-500">
            Currently, I’m focused on{" "}
            <code className=" bg-green-100">NLP and ML</code>, AI
            human-centered products.{" "}
            <code className=" bg-yellow-100">Finance</code> and playing with
            large <code className=" bg-red-100">Datasets</code> to find patterns
            are my areas of interest apart from web.
          </p>
          <p className="max-w-3xl mt-5 mx-auto text-xl text-gray-500">
            I believe coding is magic✨, The magic which enables us to create
            everything the world has never seen before.
          </p>
          <p className="max-w-3xl mt-5 mx-auto text-xl text-gray-500">
            My inbox is always open. Whether you have a question or just want to
            say hi, I’ll try my best to get back to you!
          </p>
        </div>
      </section>

      <section className="bg-white">
        <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
          <p className="mt-8 text-base leading-6 text-center text-gray-400 font-['Courier_Prime']">
            Made with 💜 by Shiva Kumar Mangina, Feel free to copy.
          </p>
        </div>
      </section>
    </>
  );
}
